@import '../../variables';

.dashboard {
    width: 100%;
    height: 100%;

    .dashboard-notification {
        padding: 2em;
        font-family: $font-stack-barlow;
        font-weight: bold;
    }

    .dashboard-content {
        width: 100%;
        height: 100%;
        background-color: #f7f7f7;
        overflow-y: hidden;

        .main-wrapper {
            position: relative;
            width: calc(100% - 55px);
            height: 100%;
            float: left;

            .content {
                height: calc(100% - 64px);

                h1 {
                    &.title {
                        font-family: $font-stack-poppins;
                        font-size: 1.8em;
                        font-weight: bold;
                        margin-top: 4.7%;
                    }
                }
            }
        }

        &::after {
            content: "";
            clear: both;
            display: table;
        }
    }
}

@media (max-width: 700px) {
    .dashboard {
        .dashboard-content {
            .main-wrapper {
                width: 100%;

                #main-nav-bar {
                    height: 50px;
                }

                .content {
                    height: calc(100% - 50px);
                }
            }
        }
    }
}
