/* Variables */
$font-stack-poppins: 'Poppins', sans-serif;
$font-stack-barlow: 'Barlow', sans-serif;

$font-stack-description: 'Poppins', sans-serif;
$font-stack-highlighted-text: 'Poppins', sans-serif;
$font-stack-header: 'Barlow', sans-serif;
$font-stack-regular-text: 'Barlow', sans-serif;
$font-stack-icons: 'FontAwesome';

$font-stack-regular-text-color: #000000;
$font-stack-regular-text-placeholder-color: #798088;

$invalid-font-family: 'Barlow', sans-serif;
$invalid-font-color: #ff0000;

$text-box-disabled-background-color: #e9ecef;

$feedback-font-family: 'Barlow', sans-serif;
$feedback-success-font-family: 'Barlow', sans-serif;
$feedback-success-font-weight: bold;
$feedback-success-color: #2BCA00;
$feedback-error-font-family: 'Barlow', sans-serif;
$feedback-error-font-weight: normal;
$feedback-error-color: #ff0000;

$btn-primary-background-color: #641bcc;
$btn-primary-color: #ffffff;
$btn-primary-border-color: #641bcc;
$btn-primary-disabled-background-color: #bebebe; // #dcdee0;
$btn-primary-disabled-color: #ffffff;
$btn-primary-disabled-border-color:  #bebebe; // #dcdee0;
$btn-primary-font-family: 'Poppins', sans-serif;

$btn-secondary-background-color: #ffffff;
$btn-secondary-color: #000000;
$btn-secondary-border-color: #641bcc;
$btn-secondary-disabled-background-color: #ffffff;
$btn-secondary-disabled-color: #bebebe; // #dcdee0;
$btn-secondary-disabled-border-color: #bebebe; // #dcdee0;
$btn-secondary-font-family: 'Poppins', sans-serif;

$rounded-corners-wrapper-background-color: #ffffff;
$rounded-corners-wrapper-border-color: #c7c7c7;

$badge-primary-background-color: #641bcc;
$badge-primary-color: #ffffff;
$badge-primary-border-color: #ffffff;

$badge-secondary-background-color: #ffffff;
$badge-secondary-color: #000000;
$badge-secondary-border-color: #641bcc;

$highlight-color: #641bcc; // #9013fe

$link-color: #641bcc;
$link-visited-color: #641bcc;
$link-hover-color: #641bcc;

$left-navbar-background-color: #2A1337;

$upload-wrapper-background-color: #ffffff;
$upload-border-color: #c7c7c7;
$upload-background-color: #c7c7c72a;
$upload-hover-background-color: #c7c7c75a;
$container-border-color: rgba(0, 0, 0, 0.23);
