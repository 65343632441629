.img-placeholder {
    display: table;
    position: relative;
    background-color: #d8d8d8;
    
    .loading, .broken-image {
        position: relative;
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        &.no-info {
            height: 100%;
        }

        &:not(.no-info) {
            height: 50%;
        }

        img {
            width: auto;
            height: 4em;
            display: inline-block;
        }
    }

    .img-info {
        height: 50%;
        padding: 1em;
        
        p {
            font-size: 0.8em;
        }

        .alt {
            font-weight: normal;
            text-align: center;
        }
    }
}