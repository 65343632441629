.try-beta-banner {
   position: fixed;
   top: 0px;
   left: 0px;
   right: 0px;
   z-index: 9999999;
   background-color: black;
   color: white;
   padding: 30px;
   text-align: center;
   box-shadow: 0px 0px 30px -5px black;
   transition: 1s ease-in-out;
}

.try-beta-button {
   min-width: 100px;
   border: none;
   border-radius: 100px;
   padding: 10px 20px;
   margin: 5px;
   font-weight: bold;
   cursor: pointer;
   font-size: 120%;
}

.try-beta-button-now {
   background-color: rgb(100, 27, 204);
   color: white;
   transition: transform 0.2s linear;
}
.try-beta-button-now:hover {
   transform: scale(1.1);
}
.try-beta-button-now:active {
   transform: scale(1);
}


.try-beta-button-later {
   border: 1px solid white;
   background-color: transparent;
   color: white;
}
.try-beta-button-later:hover {
   background-color: #FFFFFF22;
}
.try-beta-button-later:active {
   background-color: #FFFFFF11;
}

.try-beta-veil {
   position: fixed;
   top: 0px;
   bottom: 0px;
   left: 0px;
   right: 0px;
   z-index: 999999;
   backdrop-filter: blur(10px);
   background-color: #000000AA;
   transition: opacity 1s ease-in-out;
}