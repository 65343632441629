@import '../../variables';

.lang-control {
    &.stand-alone {
        position: absolute;
    }

    &.dropdown {
        right: 0;

        button.btn {
            font-size: 1em;

            &.btn-lang {
                padding-right: 0;
                font-weight: normal;

                &::before{
                    font-family: $font-stack-icons;
                    font-size: 1.5em;
                    line-height: 1.5em;
                    content:"\f0ac";
                    padding-right: 5px;
                    display: inline-block;
                }

                &.btn:focus, &.btn.focus {
                    outline: 0;
                    box-shadow: none;
                }
            }
        }
    }
}

