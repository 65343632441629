@import './variables';

/* rows */

@mixin vertically-centered-row {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
}

@mixin fully-centered-row {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}

@mixin row-v_center-h_start {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
}

@mixin row-v_center-h_end {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
}

@mixin row-v_start-h_center {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: center;
}

@mixin row-v_start-h_start {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
}

@mixin row-v_start-h_space_between {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;
}

@mixin row-v_center-h_space_between {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}

/* columns */

@mixin fully-centered-col {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

@mixin col-v_start-h_center {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
}

@mixin col-v_start-h_start {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
}

@mixin col-v_start-h_end {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: flex-start;
}

@mixin col-v_end-h_start {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-end;
}

@mixin col-v_center-h_start {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
}

@mixin col-v_center-h_end {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: center;
}

/* titles */

@mixin main-header-title {
    font-family: $font-stack-header;
    font-weight: 800;
    text-transform: uppercase;
}

@mixin header-title {
    font-family: $font-stack-header;
    font-weight: 800;
    font-size: 1.25em;
    text-transform: uppercase;
}

@mixin subtitle {
    font-family: $font-stack-header;
    font-weight: 800;
    font-size: 1em;
    text-transform: uppercase;
}

/* feedback */

@mixin feedback-success {
    font-family: $feedback-success-font-family;
    font-weight: $feedback-success-font-weight;
    color: $feedback-success-color;
}

@mixin feedback-error {
    font-family: $feedback-error-font-family;
    font-weight: $feedback-error-font-weight;
    color: $feedback-error-color;
}

@mixin feedback {
    font-family: $feedback-font-family;
    padding: 1em 0;

    .success {
        @include feedback-success;
    }

    .error {
        @include feedback-error;
    }
}

/* links */
@mixin link {
    font-family: $font-stack-regular-text;
    font-size: 1em;
    font-weight: bold;
    color: $link-color;
    border-bottom: 2px solid $link-color;
    padding-bottom: 2px;

    &:link {
        text-decoration: none;
    }

    &:visited {
        text-decoration: none;
    }

    &:hover {
        text-decoration: none;
    }
}

/* links */
@mixin error-link {
    font-family: $font-stack-regular-text;
    font-size: 1em;
    font-weight: 500;
    color: $invalid-font-color;
    border-bottom: 2px solid $invalid-font-color;
    padding-bottom: 2px;

    &:link {
        text-decoration: none;
    }

    &:visited {
        text-decoration: none;
    }

    &:hover {
        text-decoration: none;
    }
}
