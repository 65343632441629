@import '../../variables';
@import '../../mixins';

#main-nav-bar {
    justify-content: flex-start;
    position: relative;
    z-index: 1000;
    height: 64px;
    box-shadow: 4px 0 4px 0 rgba(0, 0, 0, 0.27);

    &.bg-light {
        background-color: #ffffff !important;
    }

    .top-nav-wrapper-left {
        @include row-v_center-h_start;
        width: 30%;
        height: 100%;
    }

    .top-nav-wrapper-right {
        @include row-v_center-h_end;
        width: 70%;
        height: 100%;
    }

    .logo-wrapper {
        position: absolute;
        z-index: 200;
        top: 0;
        bottom: 0;
        left: 1em;
        height: 100%;
        @include fully-centered-col;

        .logo {
            display: block;
            background: url(../../../assets/images/logo_scopio.png);
            background-position: center center;
            background-repeat: no-repeat;
            background-size: contain;
            width: 2em;
            height: 2em;
        }
    }

    .see-public-profile-wrapper {
        padding-left: 1.25em;

        a {
            @include link;
        }
    }

    .navbar-nav {
        a {
            width: 100%;
            height: 100%;
        }
    }

    .app-download-buttons-collapse {
        display: flex;
        flex-direction: column;
        margin: 0 auto;
        align-items: center;

        a {
            max-width: 90%;
            height: 100%;

            .img-wrapper {
                width: fit-content;
                height: 3em;
                margin: 0.1em 0;

                img {
                    width: 100%;
                    height: 100%
                }
            }
        }
    }

    .app-download-buttons {
        display: flex;

        .img-wrapper {
            width: fit-content;
            height: 3em;
            margin-right: 1em;

            img {
                width: 100%;
                height: 100%
            }
        }
    }

    @media (max-width: 1200px) {
        .app-download-buttons {
            display: none !important;
        }
    }

    .upload-link-wrapper {
        .upload-link {
            @include row-v_center-h_space_between;
            background: url(../../../assets/images/upload_gradient-button.png);
            background-size: cover;
            width: 125px !important;
            height: 42px !important;
            margin-right: 2em;
            padding: 0 23px;
            cursor: pointer;

            &:before {
                content: "";
                display: inline-block;
                background: url(../../../assets/images/upload_icon.svg);
                background-size: 18px 18px;
                width: 18px;
                height: 18px;
            }

            .upload-link-text {
                display: inline-block;
                font-family: $font-stack-regular-text;
                color: #ffffff;
                font-weight: 800;
                font-size: 1em;
            }
        }
    }

    #dropdown-basic-button {
        min-width: 8.12em;
        height: auto;

        &.btn {
            font-family: $font-stack-poppins;
            font-size: 1em;
            font-weight: bold;
            // border-radius: 1.7em; /* 28.5px */
        }

        &.btn-primary {
            border: none;
            color: $btn-secondary-color;
            background-color: $btn-secondary-background-color;

            &:focus,
            &.focus {
                box-shadow: none;
            }
        }
    }

    .lang-control {
        margin-right: 2em;
    }

    .navbar-collapse {
        flex-grow: 0;
    }

    .navbar-toggler {
        outline: none;
        font-family: $font-stack-icons;
        font-size: 1.5em;
        font-weight: normal;
        color: #000000;
        border: none;
    }
}

@media (max-width: 700px) {
    #main-nav-bar {
        padding: 0 !important;
        justify-content: flex-end;

        .upload-link-wrapper {
            position: relative;

            .upload-link {
                margin-right: 0;
            }
        }
    }

    #responsive-navbar-nav {
        background-color: #ffffff;
        padding: 3em 1em 1em 1em;
        position: absolute;
        z-index: 200;
        top: 0;
        right: 0;
        border-bottom: 1px solid #c4c4c4;
        border-left: 1px solid #c4c4c4;

        .responsive-nav-close {
            position: absolute;
            z-index: 201;
            top: 0.5em;
            left: 1em;
            cursor: pointer;
            text-align: right;

            .responsive-nav-close-btn {
                overflow: hidden;
                line-height: 2.75em;
                outline: none;

                &:before {
                    content: '+';
                    display: block;
                    transform: rotate(45deg);
                    font-family: $font-stack-poppins;
                    font-size: 2.75em;
                    font-weight: 300;
                }
            }
        }

        .navbar-nav {
            @include col-v_center-h_start;
            font-size: 1.4em;
            min-height: 3.25rem;

            &:not(:last-of-type) {
                border-bottom: 1px solid #000000;
            }
        }

        .lang-control {
            margin: 0;

            button.btn {
                padding: 0;
            }
        }
    }
}