@import '../../variables';
@import '../../mixins.scss';

#options-nav {
    position: relative;
    min-height: 100%;
    padding: 17px 0;
    float: left;

    div[role=menu] {
        div[role=presentation] {
            &:last-of-type {
                width: 100%;
                position: absolute;
                bottom: 1.4em;
            }
        }
    }

    .scopio-logo-container {
        @include col-v_start-h_center;
        width: 55px;
        height: 85px;

        img {
            width: 30px;
            height: auto;
        }
    }

    .opt-icon {
        font-size: 2em;
    }

    &.sidenav---sidenav---_2tBP {
        background: $left-navbar-background-color;
        min-width: 55px;
        width: 55px;
    }

    .opt-nav-item {
        height: 57px;

        .sidenav---navicon---3gCRo {
            width: 55px;
        }

        & > div,
        .opt-icon {
            color: #aaa6a6;

            img, svg {
                width: 26px;
                height: 26px;
            }
        }

        &:hover {
            .opt-icon {
                color: #ffffff;
            }
        }
    }

    .sidenav---highlighted---oUx9u {
        .opt-nav-item {
            & > div,
            .opt-icon {
                color: #ffffff;
            }

            &::after {
                background: #ffffff;
                opacity: 0.2;
                -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=20)";
                filter: alpha(opacity=20);
            }
        }
    }
}